.container{
    /* padding: 20px 20px; */
    width: 100%;
}
.map_container{
    width: 100%;
    min-height: 65vh;
}
.save{
    width: 100%;
    margin-top: 20px;
}

.button_container{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: white;
    border-radius: 15px;
    padding: 10px 20px;
}
