.container {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding-top: 25%;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.header .title {
    font-size: 25px;
    font-weight: bold;
    color: #212121;
    padding: 0;
    margin: 0;
}

.header .paragraph {
    font-size: 16px;
    color: #767676;
    padding: 0;
    margin: 0;
}

.progress_container {
    margin-top: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

.progress_container .progress_header {
    display: flex;
    justify-content: space-between;
    padding: 22px 15px 0;
}

.progress_container .progress_header span{
    font-weight: bold;
}

.progress_container .progress_header span:last-child {
    color: #0179FF;
}

.progress_container .progress_header span.uploading:after {
    content: "";
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis-dot 1.5s infinite .3s;
    margin: 0 3px;
}


@keyframes ellipsis-dot {

    25% {
        content: "";
    }
    50% {
        content: ".";
    }
    75% {
        content: "..";
    }
    100% {
        content: " ...";
    }
}