.container{
    /* padding: 20px 20px; */
    width: 100%;
}
.map_container{
    width: 100%;

}
.button_container{
    width: var(--container-width);
    position: fixed;
    bottom: 0;
    background: white;
    border-radius: 15px;
    padding: 10px 13px;
    left: 50%;
    transform: translateX(-50%);

}
