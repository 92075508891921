.container {
    --padding-left-right-container: 30px;
    --margin-between-items: 35px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    padding: 15px var(--padding-left-right-container);
    --label-font-weight: normal;
    --label-font-size: 15px;
    --label-font-color: #000;

}

.header {
    display: flex;
    justify-content: space-between;
}
.items {
    list-style-type: disc;
    color: var(--label-font-color);
    font-weight: var(--label-font-weight);
    font-size: var(--label-font-size);
    margin: 0 10px;
}

.slider .MuiSlider-mark {
    height: 4px;
    background-color: #fff;
}

.slider .MuiSlider-rail {
    background-color: #9f9f9f;
}