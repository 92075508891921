.row {
    background: var(--background-color);
    color: var(--text-font-color);
    font-weight: var(--text-font-weight);
    font-size: var(--text-font-size);
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
    margin: 0px;

}

.image_container {
    display: flex;
    justify-content: center;
    padding: 5px;

}

.image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
