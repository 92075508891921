.container {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: space-between;
    gap: 20px;
    padding: 0px 5px;

}


.header h1 {
    /*margin-bottom: 20px;*/
    font-size: 25px;
}

.header ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.header ul li {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header ul li:not(:last-child) {
    margin-bottom: 12px;
}

.header ul li .icon {
    font-size: 18px;
    color: #12bb00;
}

.customIcon {
    font-size: 18px;
    color: #12bb00;
}

.text {
    color: rgb(116, 116, 116);
    font-size: 15px;
}

.content {
    width: 100%;
}
@media only screen and (max-width: 1000px) {
    .content {
       width: 100%;
    }
}

.verticalAlign{
    vertical-align: middle !important
}

.flexContainer {
    display: flex;
    align-items: stretch;
    vertical-align: middle;
    padding-top: 10px;

}