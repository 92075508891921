.row {
    display: flex;
    justify-content: space-between;
}

.col {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 20px;
}
