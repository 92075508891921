.container {
    /*text-align: center;*/
    margin: auto;
}

.title {
    font-size: 30px;
    font-weight: bold;
    /* text-align: center; */
}

.content {
    /* padding-top: 20px */
    padding-top: 5px;
    padding-bottom: 70px;
}

.previous {
    font-size: 24px;
    display: flex;
    align-items: center;
    gap: 6px;
    text-align: unset !important;

}

.previous > .icon {
    cursor: pointer;

}


.previous > .text {
     align-items: center;
}