.container {
    background-color: rgb(242, 242, 246);
    padding: 10px;
}

.box {
    background-color: white;
    border: 1pt solid white;
    border-radius: 5px;
    padding: 10px;
}

.icon {
    display: flex;
    justify-content: flex-end;

}
