.container {
    /* padding: 20px calc(100% / 5); */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    margin: 5px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container .items {
    display: flex;
    justify-content: space-between;

}
.phoneCloseIcon_container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.container .item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .item.phone {
    gap: 10px;
}





/*.navbar__container .navbar__item*/