.container {
    border-radius: 15px;
    padding-top:10px;
    padding-bottom: 10px;
    width: 100%;
    border: 1px solid #d9d9d969;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);

}
.select_field fieldset{
    border: none;
}

.text_overflow {
    display: block;
    overflow: hidden;
    max-height: 2em;
    line-height: 1.2em;
    text-overflow: ellipsis;
}




