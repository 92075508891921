.content-container {
    margin: 0 auto;
    position: relative;
}

.content-container {
    max-width: var(--container-width);
    padding: 0px 15px;
}

/*@media(min-width: 1301px){*/
/*    .content-container {*/
/*        width: 40%;*/
/*        padding: 20px;*/
/*    }*/
/*}*/


/*@media(max-width: 1300px){*/
/*    .content-container {*/
/*        width: 50%;*/
/*        padding: 20px;*/
/*    }*/
/*}*/

/*@media(max-width: 1000px){*/
/*    .content-container {*/
/*        width: 70%;*/
/*        padding: 20px;*/
/*    }*/
/*}*/


/*@media(max-width: 700px){*/
/*    .content-container {*/
/*        width: unset;*/
/*        padding: 30px;*/
/*    }*/
/*}*/