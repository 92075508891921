.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 40px;
    margin-top: 30px;
}


.header h1 {
    font-size: 25px;
    padding: 0;
    margin: 0;
}

.header ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.header ul li {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header ul li:not(:last-child) {
    margin-bottom: 12px;
}

.header ul li .icon {
    font-size: 18px;
    color: #12bb00;
}



.content {
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .content {
       width: 100%;
    }
}
