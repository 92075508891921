
.container {
    --background-color: #fff;
    --border-color: #fff;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
}

.container > h1 {
    margin-bottom: 10px;
    font-size: 25px;
}

.container p {
    margin-bottom: 10px;
}
.icon{
    font-size: 18px;
    color: #12bb00;
}

.verticalAlign{
    vertical-align: middle;
}

.flexContainer {
    display: flex;
    align-items: stretch;
    vertical-align: middle;
}