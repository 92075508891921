.container {
    --text-font-weight: normal;
    --text-font-size: 18px;
    --text-font-color: #000;
    --background-color: #ffff;
    border-radius: 15px;
    border: 1px dashed #2674cf;
    height: 120px;
    padding: 10px;
}
.progress-container .progress {
    width: 80%;
    position: absolute;
    top: 80%;
    border-radius: 7px;
    height: 5px;
}

.row {
    background: var(--background-color);
    color: var(--text-font-color);
    font-weight: var(--text-font-weight);
    font-size: var(--text-font-size);
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    margin: 0px;

}

.image_container {
    display: flex;
    justify-content: end;
    height: 100%;
    padding: 5px;

}

.image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 50%;
    width: 50%;
    border-radius: 13px;
    border: 1px solid #eee;
}








