.tool{
    direction: ltr;
}
.container {
    border-radius: 15px;
    margin-top: 1em;
    background: #f3f3f3;
    padding: 10px 0;
}
.card_row{
    margin: 10px 5px;
    padding: 10px;
}

.description{
    margin-top: 10px;
    color:#9E9EA1;
}
.img_container{
    display: flex;
    justify-content: end;

}
.img{
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.carousel-control-next-icon,.carousel-control-prev-icon{
    display: none;
}

















