.container {
    margin-top: 1em;
}

.align_right {
    text-align: right;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
    z-index: 1;
    padding: 0px 15px;
    left: 0;
    border-radius: 20px;
    height: 60px;

}
.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end !important;
}

.first_icon_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50px !important;
    height: 26px !important;
}

.row{
    vertical-align: middle;
    align-items: center;
}

.text_overflow  {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.5em;
    word-break: break-word;
    overflow: hidden;
}


.float_right {
    float: right;
}

.float_left {
    float: left;
}

.float_clear {
    float: none;
}

.vertical_alignment {
    vertical-align: middle;
}