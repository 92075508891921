.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7px;
}

.percentage {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}

.percentage p {
    font-size: 14px;
}