.container {
    border-radius: 10px;
    background-color: var(--background-color);
    --border-color: var(--border-color);
    border: 1px solid var(--border-color);
    padding: 10px 5px;


}

.progress-container .progress {
    width: 80%;
    position: absolute;
    top: 80%;
    border-radius: 7px;
    height: 5px;
}

.square_type {
    height: 120px;
    width: 120px;
    border: 1px solid #0179ff;
    border-radius: 10px;
    position: relative;
    padding: 0 !important;

}

.square_image {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    margin-bottom: -8px;
    margin-top: 0px;

    object-fit: cover;
}

.align_icon {
    color: white;
    display: block;
    margin: auto;
}

.align_items {
    width: inherit;
    height: inherit;
    position: absolute;
    top: 1px;
    left: 0;
    bottom: 0;
    right: 0;
}

.edit_icon {
    border-radius: 50%;
    background-color: #0a58ca;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -10px;
    bottom: -10px;
    padding: 5px;
    height: 20px;
    width: 20px;

}

.row {
    /*padding: 5px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    margin: 0px;


}

.icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 60px;
    padding: 5px;
    background-color: #0179ff;
    border-radius: 10px;

}
.camera_icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: inherit;
}

.icon_col {
    display: flex;
    justify-content: flex-end;
}
.text {
    margin: 0;
}





