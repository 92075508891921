.container {
    --text-font-weight: normal;
    --text-font-size: 18px;
    --text-font-color: #000;
    --text-font-align: center;
    margin-top: 1em;
}

.container p{
    font-weight: var(--text-font-weight);
    text-align: var(--text-font-align);
    font-size: var(--text-font-size);
    color: var(--text-font-color);
}

.verticalAlign{
    vertical-align: middle;
}

.flexContainer {
    display: flex;
    align-items: stretch;
    vertical-align: middle;
}