.container {
    padding: 10px;
}

.box {
    padding: 5px 10px 15px 10px;
    border: 1pt solid #b78588;
    background-color: var(--background-color);
}

.box h1 {
    font-size: var(--title-font-size);
    font-weight: var(--title-font-weight);
    color: var(--title-font-color);
}

.box p {
    font-size: var(--subtitle-font-size);
    color: var(--subtitle-font-color);
    font-weight: var(--subtitle-font-weight);
}

.icon {
    height: 25px;
    width: 25px;
    color: #378BECFF

}

.icon_text_container {
    display: flex;

}

.icon_text_container span {
    padding-left: 5px;

}