.container {

}

.tags_row {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
}

.box {
    border: 2pt solid rgb(226, 225, 225);
    border-radius: 140px;
    padding: 0px 30px;
    text-align: center;
    font-weight: bolder;
}

.customButton {
    padding: 10px 20px !important;
    color: black;
    background-color: white;
    font-weight: bolder;
    border-radius: 140px;
    border: 1px solid #E6E6E6;
    cursor: pointer;
    text-align: center;

}

.active{
    background-color: #0079ff;
    border: 1px solid #E9E9E9;
    color: white;
    font-size : 15px
}
/*.css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected{
    color: #fff !important;
    background-color: #0079ff!important;
}*/

