*, ::before, ::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

:root {
    --container-width: 500px
}


.cursor__pointer {
    cursor: pointer;
}


*{
  font-family: 'Vazirmatn', sans-serif !important
}

.grayBtn{
  background-color: gray !important;
  color : black !important;
}