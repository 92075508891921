.container {
    --edit-container-box-length: 32px
}

.container {
    position: absolute !important;
    right: 5%;
    top: 10%;
    background-color: #fff !important;
    border-radius: 50% !important;
    width: var(--edit-container-box-length) !important;
    height: var(--edit-container-box-length) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color :black !important;
}