.container {
    /*padding: 20px 15px;*/
    display: flex;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 2px;
    direction: ltr;


}
.parent_container{
    border-radius: 10px !important;
    border: 1px solid #ebebef !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
}

.line{
    margin-top: -2px;
}
.country_container {
    width: 100px;
    display: flex;
    gap: 0;
    border: 1px solid #ced4da;
    align-items: center;
    padding: 5px;
}
.custom-flex {
    display: flex;
    align-items: center;
}

.border_0 {
    border: 0 !important;
}
.img_label {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

}

.flag {
    display: flex;
    flex-wrap: nowrap;
margin-top: -10px;
    margin-bottom: -5px;
}
.image {
    width: 100%;
}

.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25px;
}

.switch {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-right: -15px;
    margin-top: 5px;
    margin-left: -15px;


}
/* Chrome, Safari, Edge, Opera */
.border_0 input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

/* Firefox */
.border_0 input[type=number] {
    -moz-appearance: textfield;
}