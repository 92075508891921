.container {
    --padding-left-right-container: 30px;
    --margin-between-items: 35px;

    --title-font-weight: normal;
    --title-font-size: 15px;
    --title-font-color: #000;

}


.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    padding: 15px var(--padding-left-right-container);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    margin-top: 5px;

}

.header p {
    color: var(--title-font-color);
    font-weight: var(--title-font-weight);
    font-size: var(--title-font-size);
}

.items {
    list-style-type: disc;
    font-size: 18px !important;
    margin: 0;
    padding: 15px;

}

.slider .MuiSlider-mark {
    height: 4px;
    background-color: #fff;
}

.slider .MuiSlider-rail {
    background-color: #9f9f9f;
}

/*.css-pvzbzb-MuiSlider-root.Mui-disabled*/
/*{*/
/*    color: red;*/
/*}*/

.muiGreenOverwrite{
    color : #44BD26 !important;
}