.container {
    padding:0;
    border-radius: 15px;
    border: 1px solid #d9d9d969;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);

}

.container.preview {
    width: 100%;
}


.image_text {
    display: flex;
    gap: 7px;
}

.image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40px;
    margin: 5px;
}

.text p {
    opacity: 0.75;
    margin-top: 5px;
}

.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 5px;
}

.items_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.item {
    padding: 5px 30px 0px 15px;
    cursor: pointer;
    transition: .3s;
    border-radius: 8px;

}

.text_overflow  {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.25em;
    word-break: break-word;
    overflow: hidden;
}

.item:hover {
    /* background: #f9f9f9; */
}

.search_field {
    border-radius: 8px;
    margin-bottom: 8px;
}

.no_found_data {
    padding: 40px;
    text-align: center !important;
    justify-content: center;
}
.disabled{
    background: #f9f9f9;
    cursor: auto ;

}

