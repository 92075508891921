.container {
    width: 100%;
}

.box {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    border: 1pt solid rgb(245, 244, 244);
}

.header h1 {
    padding: 5px;
    margin-top: 5px;
    font-size: 25px;

}



