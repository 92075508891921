.container {
    padding: 0 !important;;
}

.item {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    /*font-size: 20px;*/
    /*padding: 10px 10px;*/
    margin: 8px 0;
    border: 1px solid #d9d9d969;
    background: #FFFFFF;
    border-radius: 10px;

}


.container .row {
    padding: 0;
    margin: 0
}

.checked_container {
    display: flex;
    flex-wrap: nowrap;
    /*gap: 5px;*/
    overflow-x: auto;
    gap: 5px;
    padding: 10px 0px;
}

.checked_item_box {
    background: #333;
    color: #eee;
    border-radius: 8px;
    width: auto;
    height: 40px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.checked_item_box_container {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    width: inherit;
    white-space: nowrap;
    align-items: center;

}
.checked_container::-webkit-scrollbar {
    height: 1px;
}

/* Track */
.checked_container::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 20px;
    margin-top: 10px;


}

/* Handle */
.checked_container::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #c8c8ce;

}


/* Handle on hover */
.checked_container::-webkit-scrollbar-thumb:hover {
    background-color: #88898c;
}


.no_found_data {
    text-align: center !important;
    justify-content: center;
}


.scrollDiv {
    max-height: 49vh;
    min-height: 20vh;
    overflow: scroll;

}

.scrollDiv::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.scrollDiv::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 20px;
    margin-top: 10px;


}

/* Handle */
.scrollDiv::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #c8c8ce;

}


/* Handle on hover */
.scrollDiv::-webkit-scrollbar-thumb:hover {
    background-color: #88898c;
}

/*@media only screen and (max-width: 400px) {*/
/*    .scrollDiv {*/
/*        min-height: 30vh;*/
/*        max-height: 30vh;*/
/*    }*/
/*    .main_container{*/
/*             margin-bottom: 30px;*/

/*            }*/
/*}*/

@media only screen and (min-height: 1000px) {
    .scrollDiv {
        max-height: 50vh;
        min-height: 50vh;
    }
}
@media only screen and (max-height: 999px) {
    .scrollDiv {
        max-height: 40vh;
        min-height: 40vh;
    }
}

@media only screen and (max-height: 899px) {
    .scrollDiv {
        max-height: 35vh;
        min-height: 35vh;
    }
    .main_container{
        padding-bottom: 30px;
    }
}
@media only screen and (max-height: 800px) {
    .scrollDiv {
        max-height: 30vh;
        min-height: 30vh;
    }
}

@media only screen and (max-height:680px) {
    .scrollDiv {
        max-height: 25vh;
        min-height: 25vh;
    }
}
@media only screen and (max-height: 600px) {
    .scrollDiv {
        max-height: 20vh;
        min-height: 20vh;
    }
    .main_container{
        padding-bottom: 50px;
    }
}

/*@media only screen and (max-width: 800px) {*/
/*    .scrollDiv {*/
/*        max-height: 60vh;*/
/*        min-height: 45vh;*/
/*    }*/
/*}*/
/*@media only screen and (max-width: 700px) {*/
/*    .scrollDiv {*/
/*        max-height: 40vh;*/
/*        min-height: 45vh;*/
/*    }*/
/*}*/
/*@media only screen and (max-height: 800px) {*/
/*    .scrollDiv {*/
/*        height: calc(50vh - 50px);*/
/*        overflow: scroll;*/
/*    }*/
/*}*/

/*@media only screen and (max-height: 685px) {*/
/*    .scrollDiv {*/
/*        height: calc(50vh - 150px);*/
/*        overflow: scroll;*/
/*    }*/
/*}*/
/*@media only screen and (max-height: 568px) {*/
/*    .scrollDiv {*/
/*        height: auto;*/
/*        overflow: scroll;*/
/*    }*/
/*    .main_container{*/
/*        margin-bottom: 30px;*/

/*    }*/
/*}*/
