
.container.preview {
    width: 100%;
}

.image_container {
    width: 80px;
    height: 70px;
    display: flex;
    align-items: center;
    /*object-fit: cover;*/

}

.btn {
    box-shadow: 0 4px 40px rgba(43, 89, 255, 0.09);
    padding: 10px 12px;
    cursor: pointer;
    transition: .3s;
    border-radius: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5%;
}


.btn:hover {
    background: #f9f9f9;
}

.content {
    display: flex;
    gap: 5%;
    width: 75%;
}

.content .text {
    width: 80%;
}


.content .icon {
    width: 10%;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.text h1 {
    padding: 0;
    margin: 0;
}

.text > p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0;
    margin: 0;
}

.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 5px;
    width: 10%;
}


.search_field {
    border-radius: 8px;
    margin-bottom: 8px;
}