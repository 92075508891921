.container {
    padding: 20px 15px;
}

.container.preview {
    width: 100%;
}


.btn {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    padding: 15px 30px;
    cursor: pointer;
    transition: .3s;
    border-radius: 11px;
    display: flex;
    justify-content: space-between;
}


.btn:hover {
    background: #f9f9f9;
}


.text > p {
    text-overflow: ellipsis;
    overflow: hidden;

    white-space: nowrap;
}

.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 5px;
}


.search_field {
    border-radius: 8px;
    margin-bottom: 8px;
}

