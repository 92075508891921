
.upload-file {
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
}

.box {
    --upload-media-box-border-radius: 16px;
    --upload-media-box-height: 150px
}

.box {
    height: var(--upload-media-box-height);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
    border-radius: var(--upload-media-box-border-radius);
    box-shadow: 0 4px 40px rgba(43, 89, 255, 0.1);
    position: relative;
    overflow: hidden;
    width: auto;
}

.customImageWidth {
    max-height: 170px;
}

.box.main {
    background: #0179FF;
    color: #fff;
}

.box .icon {
    width: 50px;
}

.box .icon img {
    max-width: 100%;
    max-height: var(--upload-media-box-height);
}



.box .text {
    font-size: 15px;
    text-align: center;
    font-weight: bold;
}

.box.loaded {
    position: relative;
    background: #fff;
    overflow: hidden;
}


.box.loaded .icon {
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box.loaded .icon img {
    max-width: unset !important;
    max-height: unset !important;
    width: auto;
    height: calc(var(--upload-media-box-height) * 2);
}


.box.loaded .text {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 7px 0;
    background: #0179FF;
    border-radius: 0 0 var(--upload-media-box-border-radius) var(--upload-media-box-border-radius);
}

/*.box .edit__container {*/
/*    --edit-container-box-length: 32px*/
/*}*/

/*.box .edit__container {*/
/*    position: absolute;*/
/*    right: 5%;*/
/*    top: 10%;*/
/*    background-color: #fff;*/
/*    border-radius: 50%;*/
/*    width: var(--edit-container-box-length);*/
/*    height: var(--edit-container-box-length);*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    color :black*/
/*}*/


.progress-container {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--upload-media-box-border-radius);
    background-color: rgba(63, 63, 63, 0.6);
    width: 100%;
    height: 100%;
}

.progress-container .content {
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
}

.progress-container .progress {
    width: 80%;
    position: absolute;
    top: 80%;
    border-radius: 7px;
    height: 5px;
}

.text-center {
    text-align: center !important;
}

.MuiButtonBase-root {
    text-align: center !important;
}

@media only screen and (min-width: 1000px) {
    .container {
        /* display: grid;
        grid-template-columns: 200px 200px 200px;
        gap: 15px; */
    }
}

.mt-1 {
    margin-top: 10px;
}
.container{
    margin-bottom: 15px;
}