.button_container{
    background-color: white;
    position: fixed;
    bottom:0;
    left:0;
    width:100% ;
    height: 60px;
    display: flex;
    justify-content: center;
    z-index: 1;
}