.container {
    /*padding: 20px 15px;*/
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ababab !important;
    padding-top: 5px;

}
.parent_container{
    border-radius: 8px !important;
    border: 1px solid #ebebef !important;
}
.country_container {
    width: 100px;
    display: flex;
    gap: 0;
    border: 1px solid #ced4da;
    align-items: center;
    padding: 5px;
}
.custom-flex {
    display: flex;
    align-items: center;
}

.border_0 {
    border: 0 !important;
}
.img_label {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

}

.flag {
    display: flex;
    flex-wrap: nowrap;
}
.image {
    width: 100%;
}

.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.item{
    width: 25px;
}

.switch {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-right: -15px;
    margin-top: 5px;
    margin-left: -15px;


}